import FormInput from "../components/FormInput.jsx";
import Header from "../components/Header.jsx";
import Nav from "../components/Nav.jsx";
import Email from "../assets/icons/email-gray.svg";
import Password from "../assets/icons/password-gray.svg";
import { useEffect, useState } from "react";
import { set_username } from "../reducers/username";
import  api from "../statics/api.js"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../components/Loader.jsx";
import Ovarlay from "../components/Overlay.jsx";

import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cart from "../components/cart.compontent.jsx";
import MobileMenu from "../components/menu.component.jsx";
import SearchInput from "../components/search-input.component.jsx";
import Overlay from "../components/Overlay.jsx";
import { Helmet } from "react-helmet";


function LoginPage() {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false)
    const [isSearching, setIsSearching] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showError = (msg) => toast.warning(msg,{theme: "dark"});

    const handleInputChange = (type, value) => {
        setFormData({...formData, [type]: value});;
    }

    const handleLogIn = async (e) => {
        e.preventDefault(); // Prevent form submission and page reload
        const {  email, password } = formData;
    
        // Regular expressions for email and username validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       
        if (!email || !emailRegex.test(email)) {
            showError("Please enter a valid email address.");
            return;
        }
        if (!password || password.length < 8) {
            showError("Password should be at least 8 characters long.");
            return;
        }
  
        setLoading(true);
    
        let postData = new FormData();
        postData.append('pwd', password);
        postData.append('email', email);

        const handleRequest = async () => {
            for (let i = 0; i < 100; i++) {
                try {
                    let response = await fetch(`${api}login.php`, {
                        method: 'POST',
                        body: postData
                    });
                    let responseData = await response.text();
                    responseData = JSON.parse(responseData);
                        setLoading(false);

                    // LogIn successful
                    if (responseData.state) {
                        
                        const expiryDate = new Date(responseData.exp);
                        document.cookie = `session=${responseData.session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                        localStorage.setItem("fullname",responseData.username);
                        toast.success("Log In successfuly");
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        dispatch(set_username(localStorage.getItem("fullname")));
                        navigate("/");
                        return;
                    }
                    // Error
                    else {
                        showError(responseData.msg)
                        return                    
                    }
                } catch (error) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
        }
        handleRequest();
        
 
        
    }
    function onChangeCaptcha(value) {
       setCaptcha(value)
    }
    
    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };

    return (
        <>
        <Helmet>
            <title>PuZzLe Technology | Login</title>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
            <Nav></Nav>
            <Header onSearchClick={handleSearchClick}></Header>

            <form action="" className="mt-6 w-[95%] mx-auto px-2 py-2 rounded-2xl bg-bw-02 flex flex-col gap-2" method="">
                <FormInput placeHolder="Write your email address here ..." img={Email} type={"email"}  name="Email Address" onChange={(ele)=>handleInputChange("email",ele.target.value)}></FormInput>
                <FormInput placeHolder="Write your password here ..." img={Password} type={"password"}  name="Password" onChange={(ele)=>handleInputChange("password",ele.target.value)}></FormInput>
                
                {/* <ReCAPTCHA
                    sitekey="6LdZLLYpAAAAAKq4tOIapPQvs76SjfHNvVeo6ZQl"
                    onChange={onChangeCaptcha}
                /> */}
                    <div className="block ml-auto font-bold text-white text-xs border-b border-green pb-1 cursor-pointer" href="" >
                        <Link to={"/recover-password"}>
                            Forget password?
                        </Link>
                    </div>
                <div className="block m- font-bold text-white text-xs "  >
                </div>

                <button className="bg-blue flex align-center justify-center font-['cairo'] px-4 py-3 font-bold text-xl text-white rounded-xl" onClick={handleLogIn}>
                   Log In
                </button>
                <div className="bg-white w-3/4 h-px mx-auto my-2"></div>
                <div className="block mx-auto font-bold text-white text-xs" href="" >Don't have and account? <Link to="/signup"><span className="font-bold text-blue cursor-pointer">Sign Up</span></Link> </div>

            </form>


            {
                loading ? 
                <>
                <Loader></Loader>
                <Ovarlay></Ovarlay>
                </>

                : null
            }
            {   
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }
           <ToastContainer></ToastContainer>
           <Cart></Cart>
           <MobileMenu></MobileMenu>
            <ToastContainer />
        </>   
    )
}

export default LoginPage;
