import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import Nav from "../components/Nav";
import api from "../statics/api.js"
import ProductVertical from "../components/product-vertical.component";
import FilterIcon from "../assets/icons/filter.svg"
import ReloadIcon from "../assets/icons/reload.svg"

import Overlay from "../components/Overlay";
import Slider from '@mui/material/Slider';
import Cart from "../components/cart.compontent.jsx";

import arrowLeftGray from "../assets/icons/arrow-left-gray.svg";
import arrowRightGray from "../assets/icons/arrow-right-gray.svg";
import arrowRightColored from "../assets/icons/arrow-right-colored.svg";
import BottomNav from "../components/bottom-nav.component.jsx";
import Footer from "../components/footer.component.jsx";
import MobileMenu from "../components/menu.component.jsx";
import SearchInput from "../components/search-input.component.jsx";

import 'react-loading-skeleton/dist/skeleton.css'
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function SearchPage() {
    const { query } = useParams();

    const [isSearching, setIsSearching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [mobileFilter, setMobileFilter] = useState(false);
    const [filters, setFilters] = useState([]);
    const [newFilters, setNewFilters] = useState([]);
    const [productsLength, setProductsLength] = useState(0);
    const [searchStart, setSearchStart] = useState(0);
  
 
    const [numPages, setNumPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [value, setValue] = useState([0, 100]);
    const [max_price, setMaxValue] = useState(0);


    // Components
    const Pagination = ({ numPages, currentPage }) => {
        const paginationElements = () => {
            if (numPages <= 9) {
                return Array.from({ length: numPages }, (_, i) => i + 1);
            } else if (currentPage >= 5) {
                return [
                    currentPage, currentPage + 1, currentPage + 2, currentPage + 3, 
                    '...',
                    numPages - 2, numPages - 1, numPages
                ];
            } else {
                return [
                    1, 2, 3, 4, 
                    '...',
                    numPages - 2, numPages - 1, numPages
                ];
            }
        };
    
        return (
            <>
                {paginationElements().map((page, index) => (
                    <div
                        key={index}
                        onClick={()=>{if (page !== "...") handlePageBtn(index+1)}}
                        className={`p-4 h-12 flex items-center justify-center text-white font-bold rounded-lg border-[1.5px]  ${page !== '...' ? 'cursor-pointer' : ''} ${currentPage === page ? 'border-gradient' : 'border-bw-03'} w-fit`}
                    >
                        {page}
                    </div>
                ))}
            </>
        );
    };
    const ProductsSkeleton = () => {
        return <div className="mt-10 flex flex-col gap-6">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2 ">
            {
                Array.from({ length: 16 }).map((___, index)=> {
                    return <div
                    className={"min-w-[150px] animate-pulse  p-2 bg-bw-02 flex relative flex-col gap-2 rounded-lg flex-1 " }
                > 
                    <div role="status" class="max-w-sm ">
                        <div class="h-48 bg-gray-200 rounded-lg mb-4"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[360px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[330px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[100px] mb-2.5"></div>
                    </div>
                </div>
                }) }
                
            </div>
        </div>
    }
    const FiltesSkeleton = () => {
        return  <div className="hidden md:flex mt-10 p-4 select-none left-4 z-20 rounded-lg bg-black w-[288px] border-solid border-[1.5px] border-bw-02 text-white font-['kensmark-03'] flex-col gap-4 h-fit">
        <div role="status" class="max-w-sm animate-pulse">
            <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[360px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[330px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full  max-w-[360px]"></div>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    }
    

    const handlePageBtn =  (i) => {
        if (i > numPages || i <= 0) {

        }else {
            setCurrentPage(i)
            setSearchStart(i*20-20);
            handleSearchWithFilters(i*20-20)
        }

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };

    const loadData = async () => {
        setLoading(true);
        for (let i = 0; i < 10; i++) {
            try {
                let response = await fetch(`${api}search_product_name.php?name=${query}&start=0&end=20&filters=[]`);
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state === false) {
                    setProducts([]);
                    return;
                }
                setProducts(responseData.products);
                setProductsLength(responseData.num_products);
                const groupedFilters = responseData.filters.reduce((acc, curr) => {
                    if (acc[curr.name]) {
                      acc[curr.name].values.push(curr.value);
                    } else {
                      acc[curr.name] = { name: curr.name, values: [curr.value] };
                    }
                    return acc;
                  }, {});  
                setFilters(Object.values(groupedFilters));
                setMaxValue(responseData.max_price);
                return;
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };

    const handleCheckBox = (parent, value) => {
        let newData = [...newFilters]; // Create a copy of newFilters array
        let is_found = false;
    
        // Check if the product already exists in newData
        newData.forEach((ele, index) => {
            if (ele.name === parent && ele.value === value) {
                is_found = true;
                // Remove the existing product from newData
                newData.splice(index, 1);
            }
        });
    
        if (!is_found) {
            // Add the new product if it doesn't already exist
            newData.push({ name: parent, value: value });
        }

        setNewFilters(newData);
    };

    const handleSearchWithFilters = async (start) => {
        setLoading(true);
        for (let i = 0; i < 100; i++) {
            try {
                const filters = JSON.stringify(newFilters);
                let response = await fetch(`${api}search_product_name.php?name=${query}&start=${start}&end=${start + 20}&filters=${filters}&max_price=${Math.round(( value[1] / 100 ) * max_price)}&min_price=${Math.round(( value[0] / 100 ) * max_price)}`);
                let responseData = await response.text();
                responseData = JSON.parse(responseData);

                setLoading(false);
                if (responseData.state === false) {
                    setProducts([]);
                    break;
                }
                setProducts(responseData.products);
                setProductsLength(responseData.num_products);
                window.scroll(0,0)
                const groupedFilters = responseData.filters.reduce((acc, curr) => {
                    if (acc[curr.name]) {
                      acc[curr.name].values.push(curr.value);
                    } else {
                      acc[curr.name] = { name: curr.name, values: [curr.value] };
                    }
                    return acc;
                  }, {});  
                setFilters(Object.values(groupedFilters));
                return;
            } catch (error) {
                console.log(error)
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };

    const getNumPages = async () => {
        const x1 = productsLength / 20;
        const x2 = (x1 % 1 ) ? 1 : 0;
        setNumPages(Math.floor(x1) + x2);

    };
    
    function isFilterChecked  (parent, value) {
        const isChecked = newFilters.some(filter => filter.name === parent && filter.value === value);
        return isChecked;
    }

    const resetFilters = () => {
        setNewFilters([])
    }


    useEffect(() => {
        getNumPages();
    }, [productsLength]);

    useEffect(() => {
        setProductsLength(0);
        loadData();
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname
          })

    }, [query]);
    return (
        <>
            <Nav />
            <Header onSearchClick={handleSearchClick} />
            
            <Helmet>
                <title>PuZzLe Technology - Search For: {query}</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <main className="m-4 ">
            {
                loading ?
                <>
                {/* <Loader /> */}
                <div className="relative grid md:grid-cols-[288px,1fr] grid-cols-1 gap-4">
                    <FiltesSkeleton/>
                    <ProductsSkeleton/>
                </div>
    
                </>
                 :
                <div className="relative grid md:grid-cols-[288px,1fr] grid-cols-1 gap-4">

                    <div className="hidden md:flex mt-10 p-4 select-none left-4 z-20 rounded-lg bg-black w-[288px] border-solid border-[1.5px] border-bw-02 text-white font-['kensmark-03'] flex-col gap-4 h-fit">

                        <div className="flex justify-between items-center">
                            <span className="text-2xl">FILTER</span>
                            <img src={ReloadIcon} alt="icon" onClick={resetFilters} className="cursor-pointer"></img>
                        </div>
                        {
                            filters.map((parent,index) => {
                                return (
                                <div className="flex gap-2 flex-col" key={index}>
                                    <span>{parent.name}</span>
                                    <div className="bg-bw-02 h-[1.5px]"></div>
                                    <div className="flex gap-2 flex-col">
                                        {
                                            parent.values.map((child, idx) => {
                                                return <div className="flex gap-2 items-center" key={idx}>
                                                    <input type="checkbox" checked={isFilterChecked(parent.name, child)} onChange={() => handleCheckBox(parent.name, child)}></input>
                                                    <span className="font-['inter'] ">{child}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            })
                        }
                        <div>
                            <span>PRICE</span>
                            <div>
                                <Slider
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                />
                                <div className="flex justify-between">
                                    <span className="font-electrolize">LE { Math.round(( value[0] / 100 ) * max_price)} </span>
                                    <span className="font-['inter']">to</span>
                                    <span>LE { Math.round(( value[1] / 100 ) * max_price)}</span>
                                </div>
                            </div>
                        </div>
                        <button onClick={()=>handleSearchWithFilters(searchStart)} className="px-6 py-2 flex font-['inter'] font-bold justify-center items-center save-gradient rounded-md">Apply</button>
                    </div>
                    
                    <div className="mt-10 flex flex-col gap-6">
                        <div className="flex gap-2">
                            <img src={FilterIcon} alt="icon" className="relative z-50" onClick={() => setMobileFilter(!mobileFilter)}></img>
                            <span className="text-white font-['cairo'] ">{productsLength} Products found</span>
                        </div>
                        {
                            products.length > 0 ?
                            <>
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2 ">
                                {
                                    products.map((ele, index) => (
                                        <ProductVertical data={ele} index={index} key={index}></ProductVertical>
                                    ))
                                }
                                </div>
                                {/* Pages */}
                                <div className="flex gap-2 select-none items-center justify-center flex-wrap">
                                    <div onClick={()=>handlePageBtn(currentPage-1)} className="cursor-pointer   w-12 h-12 flex justify-center items-center rounded-lg border-[1.5px] border-bw-03 ">
                                        <img src={arrowLeftGray} alt="icon"></img>
                                    </div>
                                    <Pagination numPages={numPages} currentPage={currentPage} />
                           
                                    <div onClick={()=>handlePageBtn(currentPage+1)} className=" cursor-pointer w-12 h-12 flex justify-center items-center rounded-lg border-[1.5px] border-bw-03 ">
                                        {
                                            currentPage !== numPages ? 
                                                <img src={arrowRightColored} alt="icon"></img>
                                            : 
                                                <img src={arrowRightGray} alt="icon"></img>
                                        }
                                    </div>
                                </div>
                            </> :
                            <h1 className="text-white font-bold text-center">No results found for: "{query}"</h1>
                         }
                    </div>
                </div>
            }
            {
                mobileFilter &&
                <div className="md:hidden">
                    <Overlay></Overlay>
                    <div className="absolute z-50 top-[218.5px] p-4 select-none left-4 rounded-lg bg-black w-[288px] border-solid border-[1.5px] border-bw-02 text-white font-['kensmark-03'] flex flex-col gap-4">
                        <div className="flex justify-between items-center">
                            <span className="text-2xl">FILTER</span>
                            <img src={ReloadIcon} alt="icon"></img>
                        </div>
                        {
                            filters.map((parent, index) => {
                                return (
                                <div className="flex gap-2 flex-col" key={index}>
                                    <span>{parent.name}</span>
                                    <div className="bg-bw-02 h-[1.5px]"></div>
                                    <div className="flex gap-2 flex-col">
                                        {
                                            parent.values.map((child, idx) => {
                                                return <div className="flex gap-2 items-center" key={idx}>
                                                {
                                                    <input type="checkbox" checked={isFilterChecked(parent.name, child)} onChange={() => handleCheckBox(parent.name, child)}></input>
                                                }
                                                    <span className="font-['inter'] ">{child}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            })
                        }
                        <div>
                            <span>PRICE</span>
                            <div>
                                <Slider
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                />
                                <div className="flex justify-between">
                                    <span className="font-electrolize">LE { Math.round(( value[0] / 100 ) * max_price)} </span>
                                    <span className="font-['inter']">to</span>
                                    <span>LE { Math.round(( value[1] / 100 ) * max_price)}</span>
                                </div>
                            </div>
                        </div>
                        <button onClick={()=>handleSearchWithFilters(searchStart)} className="px-6 py-2 flex font-['inter'] font-bold justify-center items-center save-gradient rounded-md">Apply</button>
                    </div>
                </div>
            }
            {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }
            </main>
            <BottomNav currentPage={""}></BottomNav>
            <Footer></Footer>
            <Cart></Cart>
            <MobileMenu></MobileMenu>
        </>
    );
}

export default SearchPage;
